@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.hide-scrollbar {
  scrollbar-width: none; /* Firefox */
}
.hide-scrollbar::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera */
}

img {
  -webkit-user-drag: none;
  user-select: none;
}
/* 스크롤바 전체의 스타일을 설정합니다. */
::-webkit-scrollbar {
  width: 3px; /* 스크롤바의 너비를 5px로 설정 */
}

/* 스크롤바의 트랙(경로) 부분 스타일 */
::-webkit-scrollbar-track {
  background: #f1f1f1; /* 트랙의 배경색 설정 */
}

/* 스크롤바의 핸들(움직이는 부분) 스타일 */
::-webkit-scrollbar-thumb {
  background: #888; /* 핸들의 배경색 설정 */
}

/* 스크롤바 핸들을 호버할 때의 스타일 */
::-webkit-scrollbar-thumb:hover {
  background: #555; /* 호버 시 핸들의 배경색 변경 */
}
